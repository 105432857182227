/*--------------------------------------------------------------
## breadcrumb styles
--------------------------------------------------------------*/
.breadcrumb {
  background-color: white;
  border-radius: 100px;
  /*-----------------------------
       default
  -----------------------------*/
  &.default {
    background-color: white;
    padding: 6px 18px;

    box-shadow: 0 2px 4px #7e8eb11f !important;
    margin-bottom: 0;
    border: 1px solid #eaeaee;
    border-radius: 100px;
    .breadcrumb-item {
      color: #91A1BC;
      display: flex;
      align-items: center;
      padding-left: 2px;
      &::before {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='18' height='18'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z' fill='rgba(145,161,188,1)'/%3E%3C/svg%3E");
        vertical-align: middle;
        font-size: 13px;
        height: 20px;
        width: 20px;
        margin-top: 2px;
        margin-right: 2px;
        color: red;
      }
      &:first-of-type {
        &::before {
          display: none;
        }
      }
      a {
        font-size: 13px;
        font-weight: normal !important;
        color: #91A1BC;
      }
      &.active {
        font-size: 13px;
        color: $color_dark;
      }
    }
  }
}
// ================================================= Breadcrumbs
.breadcrumb {
  width: fit-content;
  background-color: transparent;
  border-radius: 0.25rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  a {
    color: $color_black;
  }
  &.has_style1 {
    border-radius: $border_radius;
    background: $color_black !important ;
    a {
      color: rgba(255, 255, 255, 0.459);
    }
    .active {
      color: rgb(255, 255, 255) !important;
    }
    .breadcrumb-item {
      position: relative;
      &::before {
        display: inline-block;
        padding-right: 0;
        color: #6c757d;
        content: "";
        margin-right: 7px;
        left: 0;
        top: 0%;
        width: 9px;
        height: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
      }
    }
  }
  &.has_stylel2 {
    border-radius: $border_radius;
    background: transparent !important ;
    a {
      color: rgba(255, 255, 255, 0.459);
    }
    .active {
      color: rgb(255, 255, 255) !important;
    }
    .breadcrumb-item {
      position: relative;
      &::before {
        display: inline-block;
        padding-right: 0;
        color: #6c757d;
        content: "";
        margin-right: 7px;
        left: 0;
        top: 0%;
        width: 9px;
        height: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
      }
    }
    &::before {
      content: none;
    }
  }

  .breadcrumb-item {
    position: relative;
    &::before {
      display: inline-block;
      padding-right: 0;
      color: #6c757d;
      content: "";
      margin-right: 5px;
      left: 0;
      top: 0%;
      width: 12px;
      height: 20px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
    }
    &:first-child {
      &::before {
        content: none;
      }
    }
  }
}
