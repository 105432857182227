.register {
  .left_part {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
  }
  .right_part {
    padding: 60px 0;

    .logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      margin-bottom: 0.5rem;
      z-index: 6;
      width: 100%;
      img {
        z-index: 6;
      }
      .createverse-title {
        color: #7c7ef7;
        font-size: 4rem;
        z-index: 6;

        @include sm {
          font-size: 3rem;
        }
      }
    }
  }
}

.login {
  height: 100%;
  @include tablet_vert_screen {
    height: auto;
  }
  .pageChange-btn {
    border: none;
    background: transparent;
    color: $color_brand;
  }
}
