.modal-content {
  position: absolute;
  border-radius: 15px;
  padding: 20px 10px;
  min-height: 370px;
}

.modal-chain {
  .modal-content {
    min-height: 200px;
  }
  }
.selectchain-title {
  width: 100%;
  text-align: center;
}

.network-logo {
  width: 40px;
  height: 50px;
  align-self: center;
}

.network-title {
  text-align: center;
}

.custom-selectchain-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 1rem 1rem 4rem 1rem;
  gap:2rem;
}

.selectchain-list {
  cursor: pointer;
  background: linear-gradient(306.61deg, rgba(25, 116, 94, 0.189) 3.25%, rgb(115 166 154 / 30%) 122.38%);
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  justify-content: center;
  align-items: center;
  &:hover,
  &:active,
  &:focus {
    background: linear-gradient(306.61deg, rgba(25, 116, 94, 0.189) 3.25%, rgba(0, 219, 168, 0.3) 122.38%);
  }
}

@media only screen and (max-width: 460px) {
  .custom-modal-btn {
    width: 18rem;
  }
  .selectchain-list {
    margin-bottom: 1rem;
  }
}
