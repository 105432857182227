// -----------🌈🌈  Margin 🌈🌈-----------
.m-0 {
  margin: 0px;
}

.m-1 {
  margin: 1px;
}

.m-2 {
  margin: 2px;
}

.m-3 {
  margin: 3px;
}

.m-4 {
  margin: 4px;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.m-25 {
  margin: 25px;
}

.m-30 {
  margin: 30px;
}

.m-35 {
  margin: 35px;
}

.m-40 {
  margin: 40px;
}

.m-45 {
  margin: 45px;
}

.m-50 {
  margin: 50px;
}

.m-55 {
  margin: 55px;
}

.m-60 {
  margin: 60px;
}

.m-65 {
  margin: 65px;
}

.m-70 {
  margin: 70px;
}

.m-75 {
  margin: 75px;
}

.m-80 {
  margin: 80px;
}

.m-85 {
  margin: 85px;
}

.m-90 {
  margin: 90px;
}

.m-95 {
  margin: 95px;
}

.m-100 {
  margin: 100px;
}

.m-105 {
  margin: 105px;
}

.m-110 {
  margin: 110px;
}

.m-115 {
  margin: 115px;
}

.m-120 {
  margin: 120px;
}

.m-auto {
  margin: auto;
}

.m-px {
  margin: 1px;
}

.m-0\.5 {
  margin: 0.5px;
}

.m-1\.5 {
  margin: 1.5px;
}

.m-2\.5 {
  margin: 2.5px;
}

.m-3\.5 {
  margin: 3.5px;
}

.-m-0 {
  margin: 0px;
}

.-m-1 {
  margin: -1px;
}

.-m-2 {
  margin: -2px;
}

.-m-3 {
  margin: -3px;
}

.-m-4 {
  margin: -4px;
}

.-m-5 {
  margin: -5px;
}

.-m-10 {
  margin: -10px;
}

.-m-15 {
  margin: -15px;
}

.-m-20 {
  margin: -20px;
}

.-m-25 {
  margin: -25px;
}

.-m-30 {
  margin: -30px;
}

.-m-35 {
  margin: -35px;
}

.-m-40 {
  margin: -40px;
}

.-m-45 {
  margin: -45px;
}

.-m-50 {
  margin: -50px;
}

.-m-55 {
  margin: -55px;
}

.-m-60 {
  margin: -60px;
}

.-m-65 {
  margin: -65px;
}

.-m-70 {
  margin: -70px;
}

.-m-75 {
  margin: -75px;
}

.-m-80 {
  margin: -80px;
}

.-m-85 {
  margin: -85px;
}

.-m-90 {
  margin: -90px;
}

.-m-95 {
  margin: -95px;
}

.-m-100 {
  margin: -100px;
}

.-m-105 {
  margin: -105px;
}

.-m-110 {
  margin: -110px;
}

.-m-115 {
  margin: -115px;
}

.-m-120 {
  margin: -120px;
}

.-m-px {
  margin: -1px;
}

.-m-0\.5 {
  margin: -0.5px;
}

.-m-1\.5 {
  margin: -1.5px;
}

.-m-2\.5 {
  margin: -2.5px;
}

.-m-3\.5 {
  margin: -3.5px;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.my-1 {
  margin-top: 1px;
  margin-bottom: 1px;
}

.mx-1 {
  margin-left: 1px;
  margin-right: 1px;
}

.my-2 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.mx-2 {
  margin-left: 2px;
  margin-right: 2px;
}

.my-3 {
  margin-top: 3px;
  margin-bottom: 3px;
}

.mx-3 {
  margin-left: 3px;
  margin-right: 3px;
}

.my-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.mx-4 {
  margin-left: 4px;
  margin-right: 4px;
}

.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mx-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.my-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mx-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mx-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.my-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.mx-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mx-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.my-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}

.mx-45 {
  margin-left: 45px;
  margin-right: 45px;
}

.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mx-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.my-55 {
  margin-top: 55px;
  margin-bottom: 55px;
}

.mx-55 {
  margin-left: 55px;
  margin-right: 55px;
}

.my-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mx-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.my-65 {
  margin-top: 65px;
  margin-bottom: 65px;
}

.mx-65 {
  margin-left: 65px;
  margin-right: 65px;
}

.my-70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.mx-70 {
  margin-left: 70px;
  margin-right: 70px;
}

.my-75 {
  margin-top: 75px;
  margin-bottom: 75px;
}

.mx-75 {
  margin-left: 75px;
  margin-right: 75px;
}

.my-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.mx-80 {
  margin-left: 80px;
  margin-right: 80px;
}

.my-85 {
  margin-top: 85px;
  margin-bottom: 85px;
}

.mx-85 {
  margin-left: 85px;
  margin-right: 85px;
}

.my-90 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.mx-90 {
  margin-left: 90px;
  margin-right: 90px;
}

.my-95 {
  margin-top: 95px;
  margin-bottom: 95px;
}

.mx-95 {
  margin-left: 95px;
  margin-right: 95px;
}

.my-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mx-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.my-105 {
  margin-top: 105px;
  margin-bottom: 105px;
}

.mx-105 {
  margin-left: 105px;
  margin-right: 105px;
}

.my-110 {
  margin-top: 110px;
  margin-bottom: 110px;
}

.mx-110 {
  margin-left: 110px;
  margin-right: 110px;
}

.my-115 {
  margin-top: 115px;
  margin-bottom: 115px;
}

.mx-115 {
  margin-left: 115px;
  margin-right: 115px;
}

.my-120 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.mx-120 {
  margin-left: 120px;
  margin-right: 120px;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.my-0\.5 {
  margin-top: 0.5px;
  margin-bottom: 0.5px;
}

.mx-0\.5 {
  margin-left: 0.5px;
  margin-right: 0.5px;
}

.my-1\.5 {
  margin-top: 1.5px;
  margin-bottom: 1.5px;
}

.mx-1\.5 {
  margin-left: 1.5px;
  margin-right: 1.5px;
}

.my-2\.5 {
  margin-top: 2.5px;
  margin-bottom: 2.5px;
}

.mx-2\.5 {
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.my-3\.5 {
  margin-top: 3.5px;
  margin-bottom: 3.5px;
}

.mx-3\.5 {
  margin-left: 3.5px;
  margin-right: 3.5px;
}

.-my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.-mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.-my-1 {
  margin-top: -1px;
  margin-bottom: -1px;
}

.-mx-1 {
  margin-left: -1px;
  margin-right: -1px;
}

.-my-2 {
  margin-top: -2px;
  margin-bottom: -2px;
}

.-mx-2 {
  margin-left: -2px;
  margin-right: -2px;
}

.-my-3 {
  margin-top: -3px;
  margin-bottom: -3px;
}

.-mx-3 {
  margin-left: -3px;
  margin-right: -3px;
}

.-my-4 {
  margin-top: -4px;
  margin-bottom: -4px;
}

.-mx-4 {
  margin-left: -4px;
  margin-right: -4px;
}

.-my-5 {
  margin-top: -5px;
  margin-bottom: -5px;
}

.-mx-5 {
  margin-left: -5px;
  margin-right: -5px;
}

.-my-10 {
  margin-top: -10px;
  margin-bottom: -10px;
}

.-mx-10 {
  margin-left: -10px;
  margin-right: -10px;
}

.-my-15 {
  margin-top: -15px;
  margin-bottom: -15px;
}

.-mx-15 {
  margin-left: -15px;
  margin-right: -15px;
}

.-my-20 {
  margin-top: -20px;
  margin-bottom: -20px;
}

.-mx-20 {
  margin-left: -20px;
  margin-right: -20px;
}

.-my-25 {
  margin-top: -25px;
  margin-bottom: -25px;
}

.-mx-25 {
  margin-left: -25px;
  margin-right: -25px;
}

.-my-30 {
  margin-top: -30px;
  margin-bottom: -30px;
}

.-mx-30 {
  margin-left: -30px;
  margin-right: -30px;
}

.-my-35 {
  margin-top: -35px;
  margin-bottom: -35px;
}

.-mx-35 {
  margin-left: -35px;
  margin-right: -35px;
}

.-my-40 {
  margin-top: -40px;
  margin-bottom: -40px;
}

.-mx-40 {
  margin-left: -40px;
  margin-right: -40px;
}

.-my-45 {
  margin-top: -45px;
  margin-bottom: -45px;
}

.-mx-45 {
  margin-left: -45px;
  margin-right: -45px;
}

.-my-50 {
  margin-top: -50px;
  margin-bottom: -50px;
}

.-mx-50 {
  margin-left: -50px;
  margin-right: -50px;
}

.-my-55 {
  margin-top: -55px;
  margin-bottom: -55px;
}

.-mx-55 {
  margin-left: -55px;
  margin-right: -55px;
}

.-my-60 {
  margin-top: -60px;
  margin-bottom: -60px;
}

.-mx-60 {
  margin-left: -60px;
  margin-right: -60px;
}

.-my-65 {
  margin-top: -65px;
  margin-bottom: -65px;
}

.-mx-65 {
  margin-left: -65px;
  margin-right: -65px;
}

.-my-70 {
  margin-top: -70px;
  margin-bottom: -70px;
}

.-mx-70 {
  margin-left: -70px;
  margin-right: -70px;
}

.-my-75 {
  margin-top: -75px;
  margin-bottom: -75px;
}

.-mx-75 {
  margin-left: -75px;
  margin-right: -75px;
}

.-my-80 {
  margin-top: -80px;
  margin-bottom: -80px;
}

.-mx-80 {
  margin-left: -80px;
  margin-right: -80px;
}

.-my-85 {
  margin-top: -85px;
  margin-bottom: -85px;
}

.-mx-85 {
  margin-left: -85px;
  margin-right: -85px;
}

.-my-90 {
  margin-top: -90px;
  margin-bottom: -90px;
}

.-mx-90 {
  margin-left: -90px;
  margin-right: -90px;
}

.-my-95 {
  margin-top: -95px;
  margin-bottom: -95px;
}

.-mx-95 {
  margin-left: -95px;
  margin-right: -95px;
}

.-my-100 {
  margin-top: -100px;
  margin-bottom: -100px;
}

.-mx-100 {
  margin-left: -100px;
  margin-right: -100px;
}

.-my-105 {
  margin-top: -105px;
  margin-bottom: -105px;
}

.-mx-105 {
  margin-left: -105px;
  margin-right: -105px;
}

.-my-110 {
  margin-top: -110px;
  margin-bottom: -110px;
}

.-mx-110 {
  margin-left: -110px;
  margin-right: -110px;
}

.-my-115 {
  margin-top: -115px;
  margin-bottom: -115px;
}

.-mx-115 {
  margin-left: -115px;
  margin-right: -115px;
}

.-my-120 {
  margin-top: -120px;
  margin-bottom: -120px;
}

.-mx-120 {
  margin-left: -120px;
  margin-right: -120px;
}

.-my-px {
  margin-top: -1px;
  margin-bottom: -1px;
}

.-mx-px {
  margin-left: -1px;
  margin-right: -1px;
}

.-my-0\.5 {
  margin-top: -0.5px;
  margin-bottom: -0.5px;
}

.-mx-0\.5 {
  margin-left: -0.5px;
  margin-right: -0.5px;
}

.-my-1\.5 {
  margin-top: -1.5px;
  margin-bottom: -1.5px;
}

.-mx-1\.5 {
  margin-left: -1.5px;
  margin-right: -1.5px;
}

.-my-2\.5 {
  margin-top: -2.5px;
  margin-bottom: -2.5px;
}

.-mx-2\.5 {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.-my-3\.5 {
  margin-top: -3.5px;
  margin-bottom: -3.5px;
}

.-mx-3\.5 {
  margin-left: -3.5px;
  margin-right: -3.5px;
}

.mt-0 {
  margin-top: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px;
}

.mt-1 {
  margin-top: 1px;
}

.mr-1 {
  margin-right: 1px;
}

.mb-1 {
  margin-bottom: 1px;
}

.ml-1 {
  margin-left: 1px;
}

.mt-2 {
  margin-top: 2px;
}

.mr-2 {
  margin-right: 2px;
}

.mb-2 {
  margin-bottom: 2px;
}

.ml-2 {
  margin-left: 2px;
}

.mt-3 {
  margin-top: 3px;
}

.mr-3 {
  margin-right: 3px;
}

.mb-3 {
  margin-bottom: 3px;
}

.ml-3 {
  margin-left: 3px;
}

.mt-4 {
  margin-top: 4px;
}

.mr-4 {
  margin-right: 4px;
}

.mb-4 {
  margin-bottom: 4px;
}

.ml-4 {
  margin-left: 4px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mr-105 {
  margin-right: 105px;
}

.mb-105 {
  margin-bottom: 105px;
}

.ml-105 {
  margin-left: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mr-110 {
  margin-right: 110px;
}

.mb-110 {
  margin-bottom: 110px;
}

.ml-110 {
  margin-left: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mr-115 {
  margin-right: 115px;
}

.mb-115 {
  margin-bottom: 115px;
}

.ml-115 {
  margin-left: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mr-120 {
  margin-right: 120px;
}

.mb-120 {
  margin-bottom: 120px;
}

.ml-120 {
  margin-left: 120px;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mt-px {
  margin-top: 1px;
}

.mr-px {
  margin-right: 1px;
}

.mb-px {
  margin-bottom: 1px;
}

.ml-px {
  margin-left: 1px;
}

.mt-0\.5 {
  margin-top: 0.5px;
}

.mr-0\.5 {
  margin-right: 0.5px;
}

.mb-0\.5 {
  margin-bottom: 0.5px;
}

.ml-0\.5 {
  margin-left: 0.5px;
}

.mt-1\.5 {
  margin-top: 1.5px;
}

.mr-1\.5 {
  margin-right: 1.5px;
}

.mb-1\.5 {
  margin-bottom: 1.5px;
}

.ml-1\.5 {
  margin-left: 1.5px;
}

.mt-2\.5 {
  margin-top: 2.5px;
}

.mr-2\.5 {
  margin-right: 2.5px;
}

.mb-2\.5 {
  margin-bottom: 2.5px;
}

.ml-2\.5 {
  margin-left: 2.5px;
}

.mt-3\.5 {
  margin-top: 3.5px;
}

.mr-3\.5 {
  margin-right: 3.5px;
}

.mb-3\.5 {
  margin-bottom: 3.5px;
}

.ml-3\.5 {
  margin-left: 3.5px;
}

.-mt-0 {
  margin-top: 0px;
}

.-mr-0 {
  margin-right: 0px;
}

.-mb-0 {
  margin-bottom: 0px;
}

.-ml-0 {
  margin-left: 0px;
}

.-mt-1 {
  margin-top: -1px;
}

.-mr-1 {
  margin-right: -1px;
}

.-mb-1 {
  margin-bottom: -1px;
}

.-ml-1 {
  margin-left: -1px;
}

.-mt-2 {
  margin-top: -2px;
}

.-mr-2 {
  margin-right: -2px;
}

.-mb-2 {
  margin-bottom: -2px;
}

.-ml-2 {
  margin-left: -2px;
}

.-mt-3 {
  margin-top: -3px;
}

.-mr-3 {
  margin-right: -3px;
}

.-mb-3 {
  margin-bottom: -3px;
}

.-ml-3 {
  margin-left: -3px;
}

.-mt-4 {
  margin-top: -4px;
}

.-mr-4 {
  margin-right: -4px;
}

.-mb-4 {
  margin-bottom: -4px;
}

.-ml-4 {
  margin-left: -4px;
}

.-mt-5 {
  margin-top: -5px;
}

.-mr-5 {
  margin-right: -5px;
}

.-mb-5 {
  margin-bottom: -5px;
}

.-ml-5 {
  margin-left: -5px;
}

.-mt-10 {
  margin-top: -10px;
}

.-mr-10 {
  margin-right: -10px;
}

.-mb-10 {
  margin-bottom: -10px;
}

.-ml-10 {
  margin-left: -10px;
}

.-mt-15 {
  margin-top: -15px;
}

.-mr-15 {
  margin-right: -15px;
}

.-mb-15 {
  margin-bottom: -15px;
}

.-ml-15 {
  margin-left: -15px;
}

.-mt-20 {
  margin-top: -20px;
}

.-mr-20 {
  margin-right: -20px;
}

.-mb-20 {
  margin-bottom: -20px;
}

.-ml-20 {
  margin-left: -20px;
}

.-mt-25 {
  margin-top: -25px;
}

.-mr-25 {
  margin-right: -25px;
}

.-mb-25 {
  margin-bottom: -25px;
}

.-ml-25 {
  margin-left: -25px;
}

.-mt-30 {
  margin-top: -30px;
}

.-mr-30 {
  margin-right: -30px;
}

.-mb-30 {
  margin-bottom: -30px;
}

.-ml-30 {
  margin-left: -30px;
}

.-mt-35 {
  margin-top: -35px;
}

.-mr-35 {
  margin-right: -35px;
}

.-mb-35 {
  margin-bottom: -35px;
}

.-ml-35 {
  margin-left: -35px;
}

.-mt-40 {
  margin-top: -40px;
}

.-mr-40 {
  margin-right: -40px;
}

.-mb-40 {
  margin-bottom: -40px;
}

.-ml-40 {
  margin-left: -40px;
}

.-mt-45 {
  margin-top: -45px;
}

.-mr-45 {
  margin-right: -45px;
}

.-mb-45 {
  margin-bottom: -45px;
}

.-ml-45 {
  margin-left: -45px;
}

.-mt-50 {
  margin-top: -50px;
}

.-mr-50 {
  margin-right: -50px;
}

.-mb-50 {
  margin-bottom: -50px;
}

.-ml-50 {
  margin-left: -50px;
}

.-mt-55 {
  margin-top: -55px;
}

.-mr-55 {
  margin-right: -55px;
}

.-mb-55 {
  margin-bottom: -55px;
}

.-ml-55 {
  margin-left: -55px;
}

.-mt-60 {
  margin-top: -60px;
}

.-mr-60 {
  margin-right: -60px;
}

.-mb-60 {
  margin-bottom: -60px;
}

.-ml-60 {
  margin-left: -60px;
}

.-mt-65 {
  margin-top: -65px;
}

.-mr-65 {
  margin-right: -65px;
}

.-mb-65 {
  margin-bottom: -65px;
}

.-ml-65 {
  margin-left: -65px;
}

.-mt-70 {
  margin-top: -70px;
}

.-mr-70 {
  margin-right: -70px;
}

.-mb-70 {
  margin-bottom: -70px;
}

.-ml-70 {
  margin-left: -70px;
}

.-mt-75 {
  margin-top: -75px;
}

.-mr-75 {
  margin-right: -75px;
}

.-mb-75 {
  margin-bottom: -75px;
}

.-ml-75 {
  margin-left: -75px;
}

.-mt-80 {
  margin-top: -80px;
}

.-mr-80 {
  margin-right: -80px;
}

.-mb-80 {
  margin-bottom: -80px;
}

.-ml-80 {
  margin-left: -80px;
}

.-mt-85 {
  margin-top: -85px;
}

.-mr-85 {
  margin-right: -85px;
}

.-mb-85 {
  margin-bottom: -85px;
}

.-ml-85 {
  margin-left: -85px;
}

.-mt-90 {
  margin-top: -90px;
}

.-mr-90 {
  margin-right: -90px;
}

.-mb-90 {
  margin-bottom: -90px;
}

.-ml-90 {
  margin-left: -90px;
}

.-mt-95 {
  margin-top: -95px;
}

.-mr-95 {
  margin-right: -95px;
}

.-mb-95 {
  margin-bottom: -95px;
}

.-ml-95 {
  margin-left: -95px;
}

.-mt-100 {
  margin-top: -100px;
}

.-mr-100 {
  margin-right: -100px;
}

.-mb-100 {
  margin-bottom: -100px;
}

.-ml-100 {
  margin-left: -100px;
}

.-mt-105 {
  margin-top: -105px;
}

.-mr-105 {
  margin-right: -105px;
}

.-mb-105 {
  margin-bottom: -105px;
}

.-ml-105 {
  margin-left: -105px;
}

.-mt-110 {
  margin-top: -110px;
}

.-mr-110 {
  margin-right: -110px;
}

.-mb-110 {
  margin-bottom: -110px;
}

.-ml-110 {
  margin-left: -110px;
}

.-mt-115 {
  margin-top: -115px;
}

.-mr-115 {
  margin-right: -115px;
}

.-mb-115 {
  margin-bottom: -115px;
}

.-ml-115 {
  margin-left: -115px;
}

.-mt-120 {
  margin-top: -120px;
}

.-mr-120 {
  margin-right: -120px;
}

.-mb-120 {
  margin-bottom: -120px;
}

.-ml-120 {
  margin-left: -120px;
}

.-mt-px {
  margin-top: -1px;
}

.-mr-px {
  margin-right: -1px;
}

.-mb-px {
  margin-bottom: -1px;
}

.-ml-px {
  margin-left: -1px;
}

.-mt-0\.5 {
  margin-top: -0.5px;
}

.-mr-0\.5 {
  margin-right: -0.5px;
}

.-mb-0\.5 {
  margin-bottom: -0.5px;
}

.-ml-0\.5 {
  margin-left: -0.5px;
}

.-mt-1\.5 {
  margin-top: -1.5px;
}

.-mr-1\.5 {
  margin-right: -1.5px;
}

.-mb-1\.5 {
  margin-bottom: -1.5px;
}

.-ml-1\.5 {
  margin-left: -1.5px;
}

.-mt-2\.5 {
  margin-top: -2.5px;
}

.-mr-2\.5 {
  margin-right: -2.5px;
}

.-mb-2\.5 {
  margin-bottom: -2.5px;
}

.-ml-2\.5 {
  margin-left: -2.5px;
}

.-mt-3\.5 {
  margin-top: -3.5px;
}

.-mr-3\.5 {
  margin-right: -3.5px;
}

.-mb-3\.5 {
  margin-bottom: -3.5px;
}

.-ml-3\.5 {
  margin-left: -3.5px;
}
// -----------🌈🌈🌈🌈🌈🌈🌈🌈  Small screen 🌈🌈🌈🌈🌈🌈🌈🌈-----------
@include sm_screen {
  .sm\:m-0 {
    margin: 0px;
  }

  .sm\:m-1 {
    margin: 1px;
  }

  .sm\:m-2 {
    margin: 2px;
  }

  .sm\:m-3 {
    margin: 3px;
  }

  .sm\:m-4 {
    margin: 4px;
  }

  .sm\:m-5 {
    margin: 5px;
  }

  .sm\:m-10 {
    margin: 10px;
  }

  .sm\:m-15 {
    margin: 15px;
  }

  .sm\:m-20 {
    margin: 20px;
  }

  .sm\:m-25 {
    margin: 25px;
  }

  .sm\:m-30 {
    margin: 30px;
  }

  .sm\:m-35 {
    margin: 35px;
  }

  .sm\:m-40 {
    margin: 40px;
  }

  .sm\:m-45 {
    margin: 45px;
  }

  .sm\:m-50 {
    margin: 50px;
  }

  .sm\:m-55 {
    margin: 55px;
  }

  .sm\:m-60 {
    margin: 60px;
  }

  .sm\:m-65 {
    margin: 65px;
  }

  .sm\:m-70 {
    margin: 70px;
  }

  .sm\:m-75 {
    margin: 75px;
  }

  .sm\:m-80 {
    margin: 80px;
  }

  .sm\:m-85 {
    margin: 85px;
  }

  .sm\:m-90 {
    margin: 90px;
  }

  .sm\:m-95 {
    margin: 95px;
  }

  .sm\:m-100 {
    margin: 100px;
  }

  .sm\:m-105 {
    margin: 105px;
  }

  .sm\:m-110 {
    margin: 110px;
  }

  .sm\:m-115 {
    margin: 115px;
  }

  .sm\:m-120 {
    margin: 120px;
  }

  .sm\:m-auto {
    margin: auto;
  }

  .sm\:m-px {
    margin: 1px;
  }

  .sm\:m-0\.5 {
    margin: 0.5px;
  }

  .sm\:m-1\.5 {
    margin: 1.5px;
  }

  .sm\:m-2\.5 {
    margin: 2.5px;
  }

  .sm\:m-3\.5 {
    margin: 3.5px;
  }

  .sm\:-m-0 {
    margin: 0px;
  }

  .sm\:-m-1 {
    margin: -1px;
  }

  .sm\:-m-2 {
    margin: -2px;
  }

  .sm\:-m-3 {
    margin: -3px;
  }

  .sm\:-m-4 {
    margin: -4px;
  }

  .sm\:-m-5 {
    margin: -5px;
  }

  .sm\:-m-10 {
    margin: -10px;
  }

  .sm\:-m-15 {
    margin: -15px;
  }

  .sm\:-m-20 {
    margin: -20px;
  }

  .sm\:-m-25 {
    margin: -25px;
  }

  .sm\:-m-30 {
    margin: -30px;
  }

  .sm\:-m-35 {
    margin: -35px;
  }

  .sm\:-m-40 {
    margin: -40px;
  }

  .sm\:-m-45 {
    margin: -45px;
  }

  .sm\:-m-50 {
    margin: -50px;
  }

  .sm\:-m-55 {
    margin: -55px;
  }

  .sm\:-m-60 {
    margin: -60px;
  }

  .sm\:-m-65 {
    margin: -65px;
  }

  .sm\:-m-70 {
    margin: -70px;
  }

  .sm\:-m-75 {
    margin: -75px;
  }

  .sm\:-m-80 {
    margin: -80px;
  }

  .sm\:-m-85 {
    margin: -85px;
  }

  .sm\:-m-90 {
    margin: -90px;
  }

  .sm\:-m-95 {
    margin: -95px;
  }

  .sm\:-m-100 {
    margin: -100px;
  }

  .sm\:-m-105 {
    margin: -105px;
  }

  .sm\:-m-110 {
    margin: -110px;
  }

  .sm\:-m-115 {
    margin: -115px;
  }

  .sm\:-m-120 {
    margin: -120px;
  }

  .sm\:-m-px {
    margin: -1px;
  }

  .sm\:-m-0\.5 {
    margin: -0.5px;
  }

  .sm\:-m-1\.5 {
    margin: -1.5px;
  }

  .sm\:-m-2\.5 {
    margin: -2.5px;
  }

  .sm\:-m-3\.5 {
    margin: -3.5px;
  }

  .sm\:my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .sm\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .sm\:my-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .sm\:mx-1 {
    margin-left: 1px;
    margin-right: 1px;
  }

  .sm\:my-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .sm\:mx-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .sm\:my-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .sm\:mx-3 {
    margin-left: 3px;
    margin-right: 3px;
  }

  .sm\:my-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .sm\:mx-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .sm\:my-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .sm\:mx-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .sm\:my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .sm\:mx-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .sm\:my-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .sm\:mx-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .sm\:my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .sm\:mx-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .sm\:my-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .sm\:mx-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .sm\:my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .sm\:mx-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .sm\:my-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .sm\:mx-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .sm\:my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .sm\:mx-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .sm\:my-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .sm\:mx-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .sm\:my-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .sm\:mx-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .sm\:my-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .sm\:mx-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .sm\:my-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .sm\:mx-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .sm\:my-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .sm\:mx-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .sm\:my-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .sm\:mx-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .sm\:my-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .sm\:mx-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .sm\:my-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .sm\:mx-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .sm\:my-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .sm\:mx-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .sm\:my-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .sm\:mx-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .sm\:my-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .sm\:mx-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .sm\:my-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .sm\:mx-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .sm\:my-105 {
    margin-top: 105px;
    margin-bottom: 105px;
  }

  .sm\:mx-105 {
    margin-left: 105px;
    margin-right: 105px;
  }

  .sm\:my-110 {
    margin-top: 110px;
    margin-bottom: 110px;
  }

  .sm\:mx-110 {
    margin-left: 110px;
    margin-right: 110px;
  }

  .sm\:my-115 {
    margin-top: 115px;
    margin-bottom: 115px;
  }

  .sm\:mx-115 {
    margin-left: 115px;
    margin-right: 115px;
  }

  .sm\:my-120 {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  .sm\:mx-120 {
    margin-left: 120px;
    margin-right: 120px;
  }

  .sm\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .sm\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .sm\:my-0\.5 {
    margin-top: 0.5px;
    margin-bottom: 0.5px;
  }

  .sm\:mx-0\.5 {
    margin-left: 0.5px;
    margin-right: 0.5px;
  }

  .sm\:my-1\.5 {
    margin-top: 1.5px;
    margin-bottom: 1.5px;
  }

  .sm\:mx-1\.5 {
    margin-left: 1.5px;
    margin-right: 1.5px;
  }

  .sm\:my-2\.5 {
    margin-top: 2.5px;
    margin-bottom: 2.5px;
  }

  .sm\:mx-2\.5 {
    margin-left: 2.5px;
    margin-right: 2.5px;
  }

  .sm\:my-3\.5 {
    margin-top: 3.5px;
    margin-bottom: 3.5px;
  }

  .sm\:mx-3\.5 {
    margin-left: 3.5px;
    margin-right: 3.5px;
  }

  .sm\:-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .sm\:-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .sm\:-my-1 {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .sm\:-mx-1 {
    margin-left: -1px;
    margin-right: -1px;
  }

  .sm\:-my-2 {
    margin-top: -2px;
    margin-bottom: -2px;
  }

  .sm\:-mx-2 {
    margin-left: -2px;
    margin-right: -2px;
  }

  .sm\:-my-3 {
    margin-top: -3px;
    margin-bottom: -3px;
  }

  .sm\:-mx-3 {
    margin-left: -3px;
    margin-right: -3px;
  }

  .sm\:-my-4 {
    margin-top: -4px;
    margin-bottom: -4px;
  }

  .sm\:-mx-4 {
    margin-left: -4px;
    margin-right: -4px;
  }

  .sm\:-my-5 {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .sm\:-mx-5 {
    margin-left: -5px;
    margin-right: -5px;
  }

  .sm\:-my-10 {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .sm\:-mx-10 {
    margin-left: -10px;
    margin-right: -10px;
  }

  .sm\:-my-15 {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .sm\:-mx-15 {
    margin-left: -15px;
    margin-right: -15px;
  }

  .sm\:-my-20 {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .sm\:-mx-20 {
    margin-left: -20px;
    margin-right: -20px;
  }

  .sm\:-my-25 {
    margin-top: -25px;
    margin-bottom: -25px;
  }

  .sm\:-mx-25 {
    margin-left: -25px;
    margin-right: -25px;
  }

  .sm\:-my-30 {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .sm\:-mx-30 {
    margin-left: -30px;
    margin-right: -30px;
  }

  .sm\:-my-35 {
    margin-top: -35px;
    margin-bottom: -35px;
  }

  .sm\:-mx-35 {
    margin-left: -35px;
    margin-right: -35px;
  }

  .sm\:-my-40 {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .sm\:-mx-40 {
    margin-left: -40px;
    margin-right: -40px;
  }

  .sm\:-my-45 {
    margin-top: -45px;
    margin-bottom: -45px;
  }

  .sm\:-mx-45 {
    margin-left: -45px;
    margin-right: -45px;
  }

  .sm\:-my-50 {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .sm\:-mx-50 {
    margin-left: -50px;
    margin-right: -50px;
  }

  .sm\:-my-55 {
    margin-top: -55px;
    margin-bottom: -55px;
  }

  .sm\:-mx-55 {
    margin-left: -55px;
    margin-right: -55px;
  }

  .sm\:-my-60 {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .sm\:-mx-60 {
    margin-left: -60px;
    margin-right: -60px;
  }

  .sm\:-my-65 {
    margin-top: -65px;
    margin-bottom: -65px;
  }

  .sm\:-mx-65 {
    margin-left: -65px;
    margin-right: -65px;
  }

  .sm\:-my-70 {
    margin-top: -70px;
    margin-bottom: -70px;
  }

  .sm\:-mx-70 {
    margin-left: -70px;
    margin-right: -70px;
  }

  .sm\:-my-75 {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .sm\:-mx-75 {
    margin-left: -75px;
    margin-right: -75px;
  }

  .sm\:-my-80 {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .sm\:-mx-80 {
    margin-left: -80px;
    margin-right: -80px;
  }

  .sm\:-my-85 {
    margin-top: -85px;
    margin-bottom: -85px;
  }

  .sm\:-mx-85 {
    margin-left: -85px;
    margin-right: -85px;
  }

  .sm\:-my-90 {
    margin-top: -90px;
    margin-bottom: -90px;
  }

  .sm\:-mx-90 {
    margin-left: -90px;
    margin-right: -90px;
  }

  .sm\:-my-95 {
    margin-top: -95px;
    margin-bottom: -95px;
  }

  .sm\:-mx-95 {
    margin-left: -95px;
    margin-right: -95px;
  }

  .sm\:-my-100 {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .sm\:-mx-100 {
    margin-left: -100px;
    margin-right: -100px;
  }

  .sm\:-my-105 {
    margin-top: -105px;
    margin-bottom: -105px;
  }

  .sm\:-mx-105 {
    margin-left: -105px;
    margin-right: -105px;
  }

  .sm\:-my-110 {
    margin-top: -110px;
    margin-bottom: -110px;
  }

  .sm\:-mx-110 {
    margin-left: -110px;
    margin-right: -110px;
  }

  .sm\:-my-115 {
    margin-top: -115px;
    margin-bottom: -115px;
  }

  .sm\:-mx-115 {
    margin-left: -115px;
    margin-right: -115px;
  }

  .sm\:-my-120 {
    margin-top: -120px;
    margin-bottom: -120px;
  }

  .sm\:-mx-120 {
    margin-left: -120px;
    margin-right: -120px;
  }

  .sm\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .sm\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .sm\:-my-0\.5 {
    margin-top: -0.5px;
    margin-bottom: -0.5px;
  }

  .sm\:-mx-0\.5 {
    margin-left: -0.5px;
    margin-right: -0.5px;
  }

  .sm\:-my-1\.5 {
    margin-top: -1.5px;
    margin-bottom: -1.5px;
  }

  .sm\:-mx-1\.5 {
    margin-left: -1.5px;
    margin-right: -1.5px;
  }

  .sm\:-my-2\.5 {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }

  .sm\:-mx-2\.5 {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }

  .sm\:-my-3\.5 {
    margin-top: -3.5px;
    margin-bottom: -3.5px;
  }

  .sm\:-mx-3\.5 {
    margin-left: -3.5px;
    margin-right: -3.5px;
  }

  .sm\:mt-0 {
    margin-top: 0px;
  }

  .sm\:mr-0 {
    margin-right: 0px;
  }

  .sm\:mb-0 {
    margin-bottom: 0px;
  }

  .sm\:ml-0 {
    margin-left: 0px;
  }

  .sm\:mt-1 {
    margin-top: 1px;
  }

  .sm\:mr-1 {
    margin-right: 1px;
  }

  .sm\:mb-1 {
    margin-bottom: 1px;
  }

  .sm\:ml-1 {
    margin-left: 1px;
  }

  .sm\:mt-2 {
    margin-top: 2px;
  }

  .sm\:mr-2 {
    margin-right: 2px;
  }

  .sm\:mb-2 {
    margin-bottom: 2px;
  }

  .sm\:ml-2 {
    margin-left: 2px;
  }

  .sm\:mt-3 {
    margin-top: 3px;
  }

  .sm\:mr-3 {
    margin-right: 3px;
  }

  .sm\:mb-3 {
    margin-bottom: 3px;
  }

  .sm\:ml-3 {
    margin-left: 3px;
  }

  .sm\:mt-4 {
    margin-top: 4px;
  }

  .sm\:mr-4 {
    margin-right: 4px;
  }

  .sm\:mb-4 {
    margin-bottom: 4px;
  }

  .sm\:ml-4 {
    margin-left: 4px;
  }

  .sm\:mt-5 {
    margin-top: 5px;
  }

  .sm\:mr-5 {
    margin-right: 5px;
  }

  .sm\:mb-5 {
    margin-bottom: 5px;
  }

  .sm\:ml-5 {
    margin-left: 5px;
  }

  .sm\:mt-10 {
    margin-top: 10px;
  }

  .sm\:mr-10 {
    margin-right: 10px;
  }

  .sm\:mb-10 {
    margin-bottom: 10px;
  }

  .sm\:ml-10 {
    margin-left: 10px;
  }

  .sm\:mt-15 {
    margin-top: 15px;
  }

  .sm\:mr-15 {
    margin-right: 15px;
  }

  .sm\:mb-15 {
    margin-bottom: 15px;
  }

  .sm\:ml-15 {
    margin-left: 15px;
  }

  .sm\:mt-20 {
    margin-top: 20px;
  }

  .sm\:mr-20 {
    margin-right: 20px;
  }

  .sm\:mb-20 {
    margin-bottom: 20px;
  }

  .sm\:ml-20 {
    margin-left: 20px;
  }

  .sm\:mt-25 {
    margin-top: 25px;
  }

  .sm\:mr-25 {
    margin-right: 25px;
  }

  .sm\:mb-25 {
    margin-bottom: 25px;
  }

  .sm\:ml-25 {
    margin-left: 25px;
  }

  .sm\:mt-30 {
    margin-top: 30px;
  }

  .sm\:mr-30 {
    margin-right: 30px;
  }

  .sm\:mb-30 {
    margin-bottom: 30px;
  }

  .sm\:ml-30 {
    margin-left: 30px;
  }

  .sm\:mt-35 {
    margin-top: 35px;
  }

  .sm\:mr-35 {
    margin-right: 35px;
  }

  .sm\:mb-35 {
    margin-bottom: 35px;
  }

  .sm\:ml-35 {
    margin-left: 35px;
  }

  .sm\:mt-40 {
    margin-top: 40px;
  }

  .sm\:mr-40 {
    margin-right: 40px;
  }

  .sm\:mb-40 {
    margin-bottom: 40px;
  }

  .sm\:ml-40 {
    margin-left: 40px;
  }

  .sm\:mt-45 {
    margin-top: 45px;
  }

  .sm\:mr-45 {
    margin-right: 45px;
  }

  .sm\:mb-45 {
    margin-bottom: 45px;
  }

  .sm\:ml-45 {
    margin-left: 45px;
  }

  .sm\:mt-50 {
    margin-top: 50px;
  }

  .sm\:mr-50 {
    margin-right: 50px;
  }

  .sm\:mb-50 {
    margin-bottom: 50px;
  }

  .sm\:ml-50 {
    margin-left: 50px;
  }

  .sm\:mt-55 {
    margin-top: 55px;
  }

  .sm\:mr-55 {
    margin-right: 55px;
  }

  .sm\:mb-55 {
    margin-bottom: 55px;
  }

  .sm\:ml-55 {
    margin-left: 55px;
  }

  .sm\:mt-60 {
    margin-top: 60px;
  }

  .sm\:mr-60 {
    margin-right: 60px;
  }

  .sm\:mb-60 {
    margin-bottom: 60px;
  }

  .sm\:ml-60 {
    margin-left: 60px;
  }

  .sm\:mt-65 {
    margin-top: 65px;
  }

  .sm\:mr-65 {
    margin-right: 65px;
  }

  .sm\:mb-65 {
    margin-bottom: 65px;
  }

  .sm\:ml-65 {
    margin-left: 65px;
  }

  .sm\:mt-70 {
    margin-top: 70px;
  }

  .sm\:mr-70 {
    margin-right: 70px;
  }

  .sm\:mb-70 {
    margin-bottom: 70px;
  }

  .sm\:ml-70 {
    margin-left: 70px;
  }

  .sm\:mt-75 {
    margin-top: 75px;
  }

  .sm\:mr-75 {
    margin-right: 75px;
  }

  .sm\:mb-75 {
    margin-bottom: 75px;
  }

  .sm\:ml-75 {
    margin-left: 75px;
  }

  .sm\:mt-80 {
    margin-top: 80px;
  }

  .sm\:mr-80 {
    margin-right: 80px;
  }

  .sm\:mb-80 {
    margin-bottom: 80px;
  }

  .sm\:ml-80 {
    margin-left: 80px;
  }

  .sm\:mt-85 {
    margin-top: 85px;
  }

  .sm\:mr-85 {
    margin-right: 85px;
  }

  .sm\:mb-85 {
    margin-bottom: 85px;
  }

  .sm\:ml-85 {
    margin-left: 85px;
  }

  .sm\:mt-90 {
    margin-top: 90px;
  }

  .sm\:mr-90 {
    margin-right: 90px;
  }

  .sm\:mb-90 {
    margin-bottom: 90px;
  }

  .sm\:ml-90 {
    margin-left: 90px;
  }

  .sm\:mt-95 {
    margin-top: 95px;
  }

  .sm\:mr-95 {
    margin-right: 95px;
  }

  .sm\:mb-95 {
    margin-bottom: 95px;
  }

  .sm\:ml-95 {
    margin-left: 95px;
  }

  .sm\:mt-100 {
    margin-top: 100px;
  }

  .sm\:mr-100 {
    margin-right: 100px;
  }

  .sm\:mb-100 {
    margin-bottom: 100px;
  }

  .sm\:ml-100 {
    margin-left: 100px;
  }

  .sm\:mt-105 {
    margin-top: 105px;
  }

  .sm\:mr-105 {
    margin-right: 105px;
  }

  .sm\:mb-105 {
    margin-bottom: 105px;
  }

  .sm\:ml-105 {
    margin-left: 105px;
  }

  .sm\:mt-110 {
    margin-top: 110px;
  }

  .sm\:mr-110 {
    margin-right: 110px;
  }

  .sm\:mb-110 {
    margin-bottom: 110px;
  }

  .sm\:ml-110 {
    margin-left: 110px;
  }

  .sm\:mt-115 {
    margin-top: 115px;
  }

  .sm\:mr-115 {
    margin-right: 115px;
  }

  .sm\:mb-115 {
    margin-bottom: 115px;
  }

  .sm\:ml-115 {
    margin-left: 115px;
  }

  .sm\:mt-120 {
    margin-top: 120px;
  }

  .sm\:mr-120 {
    margin-right: 120px;
  }

  .sm\:mb-120 {
    margin-bottom: 120px;
  }

  .sm\:ml-120 {
    margin-left: 120px;
  }

  .sm\:mt-auto {
    margin-top: auto;
  }

  .sm\:mr-auto {
    margin-right: auto;
  }

  .sm\:mb-auto {
    margin-bottom: auto;
  }

  .sm\:ml-auto {
    margin-left: auto;
  }

  .sm\:mt-px {
    margin-top: 1px;
  }

  .sm\:mr-px {
    margin-right: 1px;
  }

  .sm\:mb-px {
    margin-bottom: 1px;
  }

  .sm\:ml-px {
    margin-left: 1px;
  }

  .sm\:mt-0\.5 {
    margin-top: 0.5px;
  }

  .sm\:mr-0\.5 {
    margin-right: 0.5px;
  }

  .sm\:mb-0\.5 {
    margin-bottom: 0.5px;
  }

  .sm\:ml-0\.5 {
    margin-left: 0.5px;
  }

  .sm\:mt-1\.5 {
    margin-top: 1.5px;
  }

  .sm\:mr-1\.5 {
    margin-right: 1.5px;
  }

  .sm\:mb-1\.5 {
    margin-bottom: 1.5px;
  }

  .sm\:ml-1\.5 {
    margin-left: 1.5px;
  }

  .sm\:mt-2\.5 {
    margin-top: 2.5px;
  }

  .sm\:mr-2\.5 {
    margin-right: 2.5px;
  }

  .sm\:mb-2\.5 {
    margin-bottom: 2.5px;
  }

  .sm\:ml-2\.5 {
    margin-left: 2.5px;
  }

  .sm\:mt-3\.5 {
    margin-top: 3.5px;
  }

  .sm\:mr-3\.5 {
    margin-right: 3.5px;
  }

  .sm\:mb-3\.5 {
    margin-bottom: 3.5px;
  }

  .sm\:ml-3\.5 {
    margin-left: 3.5px;
  }

  .sm\:-mt-0 {
    margin-top: 0px;
  }

  .sm\:-mr-0 {
    margin-right: 0px;
  }

  .sm\:-mb-0 {
    margin-bottom: 0px;
  }

  .sm\:-ml-0 {
    margin-left: 0px;
  }

  .sm\:-mt-1 {
    margin-top: -1px;
  }

  .sm\:-mr-1 {
    margin-right: -1px;
  }

  .sm\:-mb-1 {
    margin-bottom: -1px;
  }

  .sm\:-ml-1 {
    margin-left: -1px;
  }

  .sm\:-mt-2 {
    margin-top: -2px;
  }

  .sm\:-mr-2 {
    margin-right: -2px;
  }

  .sm\:-mb-2 {
    margin-bottom: -2px;
  }

  .sm\:-ml-2 {
    margin-left: -2px;
  }

  .sm\:-mt-3 {
    margin-top: -3px;
  }

  .sm\:-mr-3 {
    margin-right: -3px;
  }

  .sm\:-mb-3 {
    margin-bottom: -3px;
  }

  .sm\:-ml-3 {
    margin-left: -3px;
  }

  .sm\:-mt-4 {
    margin-top: -4px;
  }

  .sm\:-mr-4 {
    margin-right: -4px;
  }

  .sm\:-mb-4 {
    margin-bottom: -4px;
  }

  .sm\:-ml-4 {
    margin-left: -4px;
  }

  .sm\:-mt-5 {
    margin-top: -5px;
  }

  .sm\:-mr-5 {
    margin-right: -5px;
  }

  .sm\:-mb-5 {
    margin-bottom: -5px;
  }

  .sm\:-ml-5 {
    margin-left: -5px;
  }

  .sm\:-mt-10 {
    margin-top: -10px;
  }

  .sm\:-mr-10 {
    margin-right: -10px;
  }

  .sm\:-mb-10 {
    margin-bottom: -10px;
  }

  .sm\:-ml-10 {
    margin-left: -10px;
  }

  .sm\:-mt-15 {
    margin-top: -15px;
  }

  .sm\:-mr-15 {
    margin-right: -15px;
  }

  .sm\:-mb-15 {
    margin-bottom: -15px;
  }

  .sm\:-ml-15 {
    margin-left: -15px;
  }

  .sm\:-mt-20 {
    margin-top: -20px;
  }

  .sm\:-mr-20 {
    margin-right: -20px;
  }

  .sm\:-mb-20 {
    margin-bottom: -20px;
  }

  .sm\:-ml-20 {
    margin-left: -20px;
  }

  .sm\:-mt-25 {
    margin-top: -25px;
  }

  .sm\:-mr-25 {
    margin-right: -25px;
  }

  .sm\:-mb-25 {
    margin-bottom: -25px;
  }

  .sm\:-ml-25 {
    margin-left: -25px;
  }

  .sm\:-mt-30 {
    margin-top: -30px;
  }

  .sm\:-mr-30 {
    margin-right: -30px;
  }

  .sm\:-mb-30 {
    margin-bottom: -30px;
  }

  .sm\:-ml-30 {
    margin-left: -30px;
  }

  .sm\:-mt-35 {
    margin-top: -35px;
  }

  .sm\:-mr-35 {
    margin-right: -35px;
  }

  .sm\:-mb-35 {
    margin-bottom: -35px;
  }

  .sm\:-ml-35 {
    margin-left: -35px;
  }

  .sm\:-mt-40 {
    margin-top: -40px;
  }

  .sm\:-mr-40 {
    margin-right: -40px;
  }

  .sm\:-mb-40 {
    margin-bottom: -40px;
  }

  .sm\:-ml-40 {
    margin-left: -40px;
  }

  .sm\:-mt-45 {
    margin-top: -45px;
  }

  .sm\:-mr-45 {
    margin-right: -45px;
  }

  .sm\:-mb-45 {
    margin-bottom: -45px;
  }

  .sm\:-ml-45 {
    margin-left: -45px;
  }

  .sm\:-mt-50 {
    margin-top: -50px;
  }

  .sm\:-mr-50 {
    margin-right: -50px;
  }

  .sm\:-mb-50 {
    margin-bottom: -50px;
  }

  .sm\:-ml-50 {
    margin-left: -50px;
  }

  .sm\:-mt-55 {
    margin-top: -55px;
  }

  .sm\:-mr-55 {
    margin-right: -55px;
  }

  .sm\:-mb-55 {
    margin-bottom: -55px;
  }

  .sm\:-ml-55 {
    margin-left: -55px;
  }

  .sm\:-mt-60 {
    margin-top: -60px;
  }

  .sm\:-mr-60 {
    margin-right: -60px;
  }

  .sm\:-mb-60 {
    margin-bottom: -60px;
  }

  .sm\:-ml-60 {
    margin-left: -60px;
  }

  .sm\:-mt-65 {
    margin-top: -65px;
  }

  .sm\:-mr-65 {
    margin-right: -65px;
  }

  .sm\:-mb-65 {
    margin-bottom: -65px;
  }

  .sm\:-ml-65 {
    margin-left: -65px;
  }

  .sm\:-mt-70 {
    margin-top: -70px;
  }

  .sm\:-mr-70 {
    margin-right: -70px;
  }

  .sm\:-mb-70 {
    margin-bottom: -70px;
  }

  .sm\:-ml-70 {
    margin-left: -70px;
  }

  .sm\:-mt-75 {
    margin-top: -75px;
  }

  .sm\:-mr-75 {
    margin-right: -75px;
  }

  .sm\:-mb-75 {
    margin-bottom: -75px;
  }

  .sm\:-ml-75 {
    margin-left: -75px;
  }

  .sm\:-mt-80 {
    margin-top: -80px;
  }

  .sm\:-mr-80 {
    margin-right: -80px;
  }

  .sm\:-mb-80 {
    margin-bottom: -80px;
  }

  .sm\:-ml-80 {
    margin-left: -80px;
  }

  .sm\:-mt-85 {
    margin-top: -85px;
  }

  .sm\:-mr-85 {
    margin-right: -85px;
  }

  .sm\:-mb-85 {
    margin-bottom: -85px;
  }

  .sm\:-ml-85 {
    margin-left: -85px;
  }

  .sm\:-mt-90 {
    margin-top: -90px;
  }

  .sm\:-mr-90 {
    margin-right: -90px;
  }

  .sm\:-mb-90 {
    margin-bottom: -90px;
  }

  .sm\:-ml-90 {
    margin-left: -90px;
  }

  .sm\:-mt-95 {
    margin-top: -95px;
  }

  .sm\:-mr-95 {
    margin-right: -95px;
  }

  .sm\:-mb-95 {
    margin-bottom: -95px;
  }

  .sm\:-ml-95 {
    margin-left: -95px;
  }

  .sm\:-mt-100 {
    margin-top: -100px;
  }

  .sm\:-mr-100 {
    margin-right: -100px;
  }

  .sm\:-mb-100 {
    margin-bottom: -100px;
  }

  .sm\:-ml-100 {
    margin-left: -100px;
  }

  .sm\:-mt-105 {
    margin-top: -105px;
  }

  .sm\:-mr-105 {
    margin-right: -105px;
  }

  .sm\:-mb-105 {
    margin-bottom: -105px;
  }

  .sm\:-ml-105 {
    margin-left: -105px;
  }

  .sm\:-mt-110 {
    margin-top: -110px;
  }

  .sm\:-mr-110 {
    margin-right: -110px;
  }

  .sm\:-mb-110 {
    margin-bottom: -110px;
  }

  .sm\:-ml-110 {
    margin-left: -110px;
  }

  .sm\:-mt-115 {
    margin-top: -115px;
  }

  .sm\:-mr-115 {
    margin-right: -115px;
  }

  .sm\:-mb-115 {
    margin-bottom: -115px;
  }

  .sm\:-ml-115 {
    margin-left: -115px;
  }

  .sm\:-mt-120 {
    margin-top: -120px;
  }

  .sm\:-mr-120 {
    margin-right: -120px;
  }

  .sm\:-mb-120 {
    margin-bottom: -120px;
  }

  .sm\:-ml-120 {
    margin-left: -120px;
  }

  .sm\:-mt-px {
    margin-top: -1px;
  }

  .sm\:-mr-px {
    margin-right: -1px;
  }

  .sm\:-mb-px {
    margin-bottom: -1px;
  }

  .sm\:-ml-px {
    margin-left: -1px;
  }

  .sm\:-mt-0\.5 {
    margin-top: -0.5px;
  }

  .sm\:-mr-0\.5 {
    margin-right: -0.5px;
  }

  .sm\:-mb-0\.5 {
    margin-bottom: -0.5px;
  }

  .sm\:-ml-0\.5 {
    margin-left: -0.5px;
  }

  .sm\:-mt-1\.5 {
    margin-top: -1.5px;
  }

  .sm\:-mr-1\.5 {
    margin-right: -1.5px;
  }

  .sm\:-mb-1\.5 {
    margin-bottom: -1.5px;
  }

  .sm\:-ml-1\.5 {
    margin-left: -1.5px;
  }

  .sm\:-mt-2\.5 {
    margin-top: -2.5px;
  }

  .sm\:-mr-2\.5 {
    margin-right: -2.5px;
  }

  .sm\:-mb-2\.5 {
    margin-bottom: -2.5px;
  }

  .sm\:-ml-2\.5 {
    margin-left: -2.5px;
  }

  .sm\:-mt-3\.5 {
    margin-top: -3.5px;
  }

  .sm\:-mr-3\.5 {
    margin-right: -3.5px;
  }

  .sm\:-mb-3\.5 {
    margin-bottom: -3.5px;
  }

  .sm\:-ml-3\.5 {
    margin-left: -3.5px;
  }
}
// -----------🌈🌈🌈🌈🌈🌈🌈🌈  medium screen 🌈🌈🌈🌈🌈🌈🌈🌈-----------
@include lg {
  .md\:m-0 {
    margin: 0px;
  }

  .md\:m-1 {
    margin: 1px;
  }

  .md\:m-2 {
    margin: 2px;
  }

  .md\:m-3 {
    margin: 3px;
  }

  .md\:m-4 {
    margin: 4px;
  }

  .md\:m-5 {
    margin: 5px;
  }

  .md\:m-10 {
    margin: 10px;
  }

  .md\:m-15 {
    margin: 15px;
  }

  .md\:m-20 {
    margin: 20px;
  }

  .md\:m-25 {
    margin: 25px;
  }

  .md\:m-30 {
    margin: 30px;
  }

  .md\:m-35 {
    margin: 35px;
  }

  .md\:m-40 {
    margin: 40px;
  }

  .md\:m-45 {
    margin: 45px;
  }

  .md\:m-50 {
    margin: 50px;
  }

  .md\:m-55 {
    margin: 55px;
  }

  .md\:m-60 {
    margin: 60px;
  }

  .md\:m-65 {
    margin: 65px;
  }

  .md\:m-70 {
    margin: 70px;
  }

  .md\:m-75 {
    margin: 75px;
  }

  .md\:m-80 {
    margin: 80px;
  }

  .md\:m-85 {
    margin: 85px;
  }

  .md\:m-90 {
    margin: 90px;
  }

  .md\:m-95 {
    margin: 95px;
  }

  .md\:m-100 {
    margin: 100px;
  }

  .md\:m-105 {
    margin: 105px;
  }

  .md\:m-110 {
    margin: 110px;
  }

  .md\:m-115 {
    margin: 115px;
  }

  .md\:m-120 {
    margin: 120px;
  }

  .md\:m-auto {
    margin: auto;
  }

  .md\:m-px {
    margin: 1px;
  }

  .md\:m-0\.5 {
    margin: 0.5px;
  }

  .md\:m-1\.5 {
    margin: 1.5px;
  }

  .md\:m-2\.5 {
    margin: 2.5px;
  }

  .md\:m-3\.5 {
    margin: 3.5px;
  }

  .md\:-m-0 {
    margin: 0px;
  }

  .md\:-m-1 {
    margin: -1px;
  }

  .md\:-m-2 {
    margin: -2px;
  }

  .md\:-m-3 {
    margin: -3px;
  }

  .md\:-m-4 {
    margin: -4px;
  }

  .md\:-m-5 {
    margin: -5px;
  }

  .md\:-m-10 {
    margin: -10px;
  }

  .md\:-m-15 {
    margin: -15px;
  }

  .md\:-m-20 {
    margin: -20px;
  }

  .md\:-m-25 {
    margin: -25px;
  }

  .md\:-m-30 {
    margin: -30px;
  }

  .md\:-m-35 {
    margin: -35px;
  }

  .md\:-m-40 {
    margin: -40px;
  }

  .md\:-m-45 {
    margin: -45px;
  }

  .md\:-m-50 {
    margin: -50px;
  }

  .md\:-m-55 {
    margin: -55px;
  }

  .md\:-m-60 {
    margin: -60px;
  }

  .md\:-m-65 {
    margin: -65px;
  }

  .md\:-m-70 {
    margin: -70px;
  }

  .md\:-m-75 {
    margin: -75px;
  }

  .md\:-m-80 {
    margin: -80px;
  }

  .md\:-m-85 {
    margin: -85px;
  }

  .md\:-m-90 {
    margin: -90px;
  }

  .md\:-m-95 {
    margin: -95px;
  }

  .md\:-m-100 {
    margin: -100px;
  }

  .md\:-m-105 {
    margin: -105px;
  }

  .md\:-m-110 {
    margin: -110px;
  }

  .md\:-m-115 {
    margin: -115px;
  }

  .md\:-m-120 {
    margin: -120px;
  }

  .md\:-m-px {
    margin: -1px;
  }

  .md\:-m-0\.5 {
    margin: -0.5px;
  }

  .md\:-m-1\.5 {
    margin: -1.5px;
  }

  .md\:-m-2\.5 {
    margin: -2.5px;
  }

  .md\:-m-3\.5 {
    margin: -3.5px;
  }

  .md\:my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .md\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .md\:my-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .md\:mx-1 {
    margin-left: 1px;
    margin-right: 1px;
  }

  .md\:my-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .md\:mx-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .md\:my-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .md\:mx-3 {
    margin-left: 3px;
    margin-right: 3px;
  }

  .md\:my-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .md\:mx-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .md\:my-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .md\:mx-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .md\:my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .md\:mx-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .md\:my-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .md\:mx-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .md\:my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .md\:mx-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .md\:my-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .md\:mx-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .md\:my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .md\:mx-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .md\:my-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .md\:mx-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .md\:my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .md\:mx-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .md\:my-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .md\:mx-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .md\:my-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .md\:mx-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .md\:my-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .md\:mx-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .md\:my-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .md\:mx-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .md\:my-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .md\:mx-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .md\:my-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .md\:mx-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .md\:my-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .md\:mx-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .md\:my-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .md\:mx-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .md\:my-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .md\:mx-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .md\:my-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .md\:mx-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .md\:my-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .md\:mx-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .md\:my-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .md\:mx-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .md\:my-105 {
    margin-top: 105px;
    margin-bottom: 105px;
  }

  .md\:mx-105 {
    margin-left: 105px;
    margin-right: 105px;
  }

  .md\:my-110 {
    margin-top: 110px;
    margin-bottom: 110px;
  }

  .md\:mx-110 {
    margin-left: 110px;
    margin-right: 110px;
  }

  .md\:my-115 {
    margin-top: 115px;
    margin-bottom: 115px;
  }

  .md\:mx-115 {
    margin-left: 115px;
    margin-right: 115px;
  }

  .md\:my-120 {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  .md\:mx-120 {
    margin-left: 120px;
    margin-right: 120px;
  }

  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .md\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .md\:my-0\.5 {
    margin-top: 0.5px;
    margin-bottom: 0.5px;
  }

  .md\:mx-0\.5 {
    margin-left: 0.5px;
    margin-right: 0.5px;
  }

  .md\:my-1\.5 {
    margin-top: 1.5px;
    margin-bottom: 1.5px;
  }

  .md\:mx-1\.5 {
    margin-left: 1.5px;
    margin-right: 1.5px;
  }

  .md\:my-2\.5 {
    margin-top: 2.5px;
    margin-bottom: 2.5px;
  }

  .md\:mx-2\.5 {
    margin-left: 2.5px;
    margin-right: 2.5px;
  }

  .md\:my-3\.5 {
    margin-top: 3.5px;
    margin-bottom: 3.5px;
  }

  .md\:mx-3\.5 {
    margin-left: 3.5px;
    margin-right: 3.5px;
  }

  .md\:-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .md\:-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .md\:-my-1 {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .md\:-mx-1 {
    margin-left: -1px;
    margin-right: -1px;
  }

  .md\:-my-2 {
    margin-top: -2px;
    margin-bottom: -2px;
  }

  .md\:-mx-2 {
    margin-left: -2px;
    margin-right: -2px;
  }

  .md\:-my-3 {
    margin-top: -3px;
    margin-bottom: -3px;
  }

  .md\:-mx-3 {
    margin-left: -3px;
    margin-right: -3px;
  }

  .md\:-my-4 {
    margin-top: -4px;
    margin-bottom: -4px;
  }

  .md\:-mx-4 {
    margin-left: -4px;
    margin-right: -4px;
  }

  .md\:-my-5 {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .md\:-mx-5 {
    margin-left: -5px;
    margin-right: -5px;
  }

  .md\:-my-10 {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .md\:-mx-10 {
    margin-left: -10px;
    margin-right: -10px;
  }

  .md\:-my-15 {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .md\:-mx-15 {
    margin-left: -15px;
    margin-right: -15px;
  }

  .md\:-my-20 {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .md\:-mx-20 {
    margin-left: -20px;
    margin-right: -20px;
  }

  .md\:-my-25 {
    margin-top: -25px;
    margin-bottom: -25px;
  }

  .md\:-mx-25 {
    margin-left: -25px;
    margin-right: -25px;
  }

  .md\:-my-30 {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .md\:-mx-30 {
    margin-left: -30px;
    margin-right: -30px;
  }

  .md\:-my-35 {
    margin-top: -35px;
    margin-bottom: -35px;
  }

  .md\:-mx-35 {
    margin-left: -35px;
    margin-right: -35px;
  }

  .md\:-my-40 {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .md\:-mx-40 {
    margin-left: -40px;
    margin-right: -40px;
  }

  .md\:-my-45 {
    margin-top: -45px;
    margin-bottom: -45px;
  }

  .md\:-mx-45 {
    margin-left: -45px;
    margin-right: -45px;
  }

  .md\:-my-50 {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .md\:-mx-50 {
    margin-left: -50px;
    margin-right: -50px;
  }

  .md\:-my-55 {
    margin-top: -55px;
    margin-bottom: -55px;
  }

  .md\:-mx-55 {
    margin-left: -55px;
    margin-right: -55px;
  }

  .md\:-my-60 {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .md\:-mx-60 {
    margin-left: -60px;
    margin-right: -60px;
  }

  .md\:-my-65 {
    margin-top: -65px;
    margin-bottom: -65px;
  }

  .md\:-mx-65 {
    margin-left: -65px;
    margin-right: -65px;
  }

  .md\:-my-70 {
    margin-top: -70px;
    margin-bottom: -70px;
  }

  .md\:-mx-70 {
    margin-left: -70px;
    margin-right: -70px;
  }

  .md\:-my-75 {
    margin-top: -75px;
    margin-bottom: -75px;
  }

  .md\:-mx-75 {
    margin-left: -75px;
    margin-right: -75px;
  }

  .md\:-my-80 {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .md\:-mx-80 {
    margin-left: -80px;
    margin-right: -80px;
  }

  .md\:-my-85 {
    margin-top: -85px;
    margin-bottom: -85px;
  }

  .md\:-mx-85 {
    margin-left: -85px;
    margin-right: -85px;
  }

  .md\:-my-90 {
    margin-top: -90px;
    margin-bottom: -90px;
  }

  .md\:-mx-90 {
    margin-left: -90px;
    margin-right: -90px;
  }

  .md\:-my-95 {
    margin-top: -95px;
    margin-bottom: -95px;
  }

  .md\:-mx-95 {
    margin-left: -95px;
    margin-right: -95px;
  }

  .md\:-my-100 {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .md\:-mx-100 {
    margin-left: -100px;
    margin-right: -100px;
  }

  .md\:-my-105 {
    margin-top: -105px;
    margin-bottom: -105px;
  }

  .md\:-mx-105 {
    margin-left: -105px;
    margin-right: -105px;
  }

  .md\:-my-110 {
    margin-top: -110px;
    margin-bottom: -110px;
  }

  .md\:-mx-110 {
    margin-left: -110px;
    margin-right: -110px;
  }

  .md\:-my-115 {
    margin-top: -115px;
    margin-bottom: -115px;
  }

  .md\:-mx-115 {
    margin-left: -115px;
    margin-right: -115px;
  }

  .md\:-my-120 {
    margin-top: -120px;
    margin-bottom: -120px;
  }

  .md\:-mx-120 {
    margin-left: -120px;
    margin-right: -120px;
  }

  .md\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .md\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .md\:-my-0\.5 {
    margin-top: -0.5px;
    margin-bottom: -0.5px;
  }

  .md\:-mx-0\.5 {
    margin-left: -0.5px;
    margin-right: -0.5px;
  }

  .md\:-my-1\.5 {
    margin-top: -1.5px;
    margin-bottom: -1.5px;
  }

  .md\:-mx-1\.5 {
    margin-left: -1.5px;
    margin-right: -1.5px;
  }

  .md\:-my-2\.5 {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }

  .md\:-mx-2\.5 {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }

  .md\:-my-3\.5 {
    margin-top: -3.5px;
    margin-bottom: -3.5px;
  }

  .md\:-mx-3\.5 {
    margin-left: -3.5px;
    margin-right: -3.5px;
  }

  .md\:mt-0 {
    margin-top: 0px;
  }

  .md\:mr-0 {
    margin-right: 0px;
  }

  .md\:mb-0 {
    margin-bottom: 0px;
  }

  .md\:ml-0 {
    margin-left: 0px;
  }

  .md\:mt-1 {
    margin-top: 1px;
  }

  .md\:mr-1 {
    margin-right: 1px;
  }

  .md\:mb-1 {
    margin-bottom: 1px;
  }

  .md\:ml-1 {
    margin-left: 1px;
  }

  .md\:mt-2 {
    margin-top: 2px;
  }

  .md\:mr-2 {
    margin-right: 2px;
  }

  .md\:mb-2 {
    margin-bottom: 2px;
  }

  .md\:ml-2 {
    margin-left: 2px;
  }

  .md\:mt-3 {
    margin-top: 3px;
  }

  .md\:mr-3 {
    margin-right: 3px;
  }

  .md\:mb-3 {
    margin-bottom: 3px;
  }

  .md\:ml-3 {
    margin-left: 3px;
  }

  .md\:mt-4 {
    margin-top: 4px;
  }

  .md\:mr-4 {
    margin-right: 4px;
  }

  .md\:mb-4 {
    margin-bottom: 4px;
  }

  .md\:ml-4 {
    margin-left: 4px;
  }

  .md\:mt-5 {
    margin-top: 5px;
  }

  .md\:mr-5 {
    margin-right: 5px;
  }

  .md\:mb-5 {
    margin-bottom: 5px;
  }

  .md\:ml-5 {
    margin-left: 5px;
  }

  .md\:mt-10 {
    margin-top: 10px;
  }

  .md\:mr-10 {
    margin-right: 10px;
  }

  .md\:mb-10 {
    margin-bottom: 10px;
  }

  .md\:ml-10 {
    margin-left: 10px;
  }

  .md\:mt-15 {
    margin-top: 15px;
  }

  .md\:mr-15 {
    margin-right: 15px;
  }

  .md\:mb-15 {
    margin-bottom: 15px;
  }

  .md\:ml-15 {
    margin-left: 15px;
  }

  .md\:mt-20 {
    margin-top: 20px;
  }

  .md\:mr-20 {
    margin-right: 20px;
  }

  .md\:mb-20 {
    margin-bottom: 20px;
  }

  .md\:ml-20 {
    margin-left: 20px;
  }

  .md\:mt-25 {
    margin-top: 25px;
  }

  .md\:mr-25 {
    margin-right: 25px;
  }

  .md\:mb-25 {
    margin-bottom: 25px;
  }

  .md\:ml-25 {
    margin-left: 25px;
  }

  .md\:mt-30 {
    margin-top: 30px;
  }

  .md\:mr-30 {
    margin-right: 30px;
  }

  .md\:mb-30 {
    margin-bottom: 30px;
  }

  .md\:ml-30 {
    margin-left: 30px;
  }

  .md\:mt-35 {
    margin-top: 35px;
  }

  .md\:mr-35 {
    margin-right: 35px;
  }

  .md\:mb-35 {
    margin-bottom: 35px;
  }

  .md\:ml-35 {
    margin-left: 35px;
  }

  .md\:mt-40 {
    margin-top: 40px;
  }

  .md\:mr-40 {
    margin-right: 40px;
  }

  .md\:mb-40 {
    margin-bottom: 40px;
  }

  .md\:ml-40 {
    margin-left: 40px;
  }

  .md\:mt-45 {
    margin-top: 45px;
  }

  .md\:mr-45 {
    margin-right: 45px;
  }

  .md\:mb-45 {
    margin-bottom: 45px;
  }

  .md\:ml-45 {
    margin-left: 45px;
  }

  .md\:mt-50 {
    margin-top: 50px;
  }

  .md\:mr-50 {
    margin-right: 50px;
  }

  .md\:mb-50 {
    margin-bottom: 50px;
  }

  .md\:ml-50 {
    margin-left: 50px;
  }

  .md\:mt-55 {
    margin-top: 55px;
  }

  .md\:mr-55 {
    margin-right: 55px;
  }

  .md\:mb-55 {
    margin-bottom: 55px;
  }

  .md\:ml-55 {
    margin-left: 55px;
  }

  .md\:mt-60 {
    margin-top: 60px;
  }

  .md\:mr-60 {
    margin-right: 60px;
  }

  .md\:mb-60 {
    margin-bottom: 60px;
  }

  .md\:ml-60 {
    margin-left: 60px;
  }

  .md\:mt-65 {
    margin-top: 65px;
  }

  .md\:mr-65 {
    margin-right: 65px;
  }

  .md\:mb-65 {
    margin-bottom: 65px;
  }

  .md\:ml-65 {
    margin-left: 65px;
  }

  .md\:mt-70 {
    margin-top: 70px;
  }

  .md\:mr-70 {
    margin-right: 70px;
  }

  .md\:mb-70 {
    margin-bottom: 70px;
  }

  .md\:ml-70 {
    margin-left: 70px;
  }

  .md\:mt-75 {
    margin-top: 75px;
  }

  .md\:mr-75 {
    margin-right: 75px;
  }

  .md\:mb-75 {
    margin-bottom: 75px;
  }

  .md\:ml-75 {
    margin-left: 75px;
  }

  .md\:mt-80 {
    margin-top: 80px;
  }

  .md\:mr-80 {
    margin-right: 80px;
  }

  .md\:mb-80 {
    margin-bottom: 80px;
  }

  .md\:ml-80 {
    margin-left: 80px;
  }

  .md\:mt-85 {
    margin-top: 85px;
  }

  .md\:mr-85 {
    margin-right: 85px;
  }

  .md\:mb-85 {
    margin-bottom: 85px;
  }

  .md\:ml-85 {
    margin-left: 85px;
  }

  .md\:mt-90 {
    margin-top: 90px;
  }

  .md\:mr-90 {
    margin-right: 90px;
  }

  .md\:mb-90 {
    margin-bottom: 90px;
  }

  .md\:ml-90 {
    margin-left: 90px;
  }

  .md\:mt-95 {
    margin-top: 95px;
  }

  .md\:mr-95 {
    margin-right: 95px;
  }

  .md\:mb-95 {
    margin-bottom: 95px;
  }

  .md\:ml-95 {
    margin-left: 95px;
  }

  .md\:mt-100 {
    margin-top: 100px;
  }

  .md\:mr-100 {
    margin-right: 100px;
  }

  .md\:mb-100 {
    margin-bottom: 100px;
  }

  .md\:ml-100 {
    margin-left: 100px;
  }

  .md\:mt-105 {
    margin-top: 105px;
  }

  .md\:mr-105 {
    margin-right: 105px;
  }

  .md\:mb-105 {
    margin-bottom: 105px;
  }

  .md\:ml-105 {
    margin-left: 105px;
  }

  .md\:mt-110 {
    margin-top: 110px;
  }

  .md\:mr-110 {
    margin-right: 110px;
  }

  .md\:mb-110 {
    margin-bottom: 110px;
  }

  .md\:ml-110 {
    margin-left: 110px;
  }

  .md\:mt-115 {
    margin-top: 115px;
  }

  .md\:mr-115 {
    margin-right: 115px;
  }

  .md\:mb-115 {
    margin-bottom: 115px;
  }

  .md\:ml-115 {
    margin-left: 115px;
  }

  .md\:mt-120 {
    margin-top: 120px;
  }

  .md\:mr-120 {
    margin-right: 120px;
  }

  .md\:mb-120 {
    margin-bottom: 120px;
  }

  .md\:ml-120 {
    margin-left: 120px;
  }

  .md\:mt-auto {
    margin-top: auto;
  }

  .md\:mr-auto {
    margin-right: auto;
  }

  .md\:mb-auto {
    margin-bottom: auto;
  }

  .md\:ml-auto {
    margin-left: auto;
  }

  .md\:mt-px {
    margin-top: 1px;
  }

  .md\:mr-px {
    margin-right: 1px;
  }

  .md\:mb-px {
    margin-bottom: 1px;
  }

  .md\:ml-px {
    margin-left: 1px;
  }

  .md\:mt-0\.5 {
    margin-top: 0.5px;
  }

  .md\:mr-0\.5 {
    margin-right: 0.5px;
  }

  .md\:mb-0\.5 {
    margin-bottom: 0.5px;
  }

  .md\:ml-0\.5 {
    margin-left: 0.5px;
  }

  .md\:mt-1\.5 {
    margin-top: 1.5px;
  }

  .md\:mr-1\.5 {
    margin-right: 1.5px;
  }

  .md\:mb-1\.5 {
    margin-bottom: 1.5px;
  }

  .md\:ml-1\.5 {
    margin-left: 1.5px;
  }

  .md\:mt-2\.5 {
    margin-top: 2.5px;
  }

  .md\:mr-2\.5 {
    margin-right: 2.5px;
  }

  .md\:mb-2\.5 {
    margin-bottom: 2.5px;
  }

  .md\:ml-2\.5 {
    margin-left: 2.5px;
  }

  .md\:mt-3\.5 {
    margin-top: 3.5px;
  }

  .md\:mr-3\.5 {
    margin-right: 3.5px;
  }

  .md\:mb-3\.5 {
    margin-bottom: 3.5px;
  }

  .md\:ml-3\.5 {
    margin-left: 3.5px;
  }

  .md\:-mt-0 {
    margin-top: 0px;
  }

  .md\:-mr-0 {
    margin-right: 0px;
  }

  .md\:-mb-0 {
    margin-bottom: 0px;
  }

  .md\:-ml-0 {
    margin-left: 0px;
  }

  .md\:-mt-1 {
    margin-top: -1px;
  }

  .md\:-mr-1 {
    margin-right: -1px;
  }

  .md\:-mb-1 {
    margin-bottom: -1px;
  }

  .md\:-ml-1 {
    margin-left: -1px;
  }

  .md\:-mt-2 {
    margin-top: -2px;
  }

  .md\:-mr-2 {
    margin-right: -2px;
  }

  .md\:-mb-2 {
    margin-bottom: -2px;
  }

  .md\:-ml-2 {
    margin-left: -2px;
  }

  .md\:-mt-3 {
    margin-top: -3px;
  }

  .md\:-mr-3 {
    margin-right: -3px;
  }

  .md\:-mb-3 {
    margin-bottom: -3px;
  }

  .md\:-ml-3 {
    margin-left: -3px;
  }

  .md\:-mt-4 {
    margin-top: -4px;
  }

  .md\:-mr-4 {
    margin-right: -4px;
  }

  .md\:-mb-4 {
    margin-bottom: -4px;
  }

  .md\:-ml-4 {
    margin-left: -4px;
  }

  .md\:-mt-5 {
    margin-top: -5px;
  }

  .md\:-mr-5 {
    margin-right: -5px;
  }

  .md\:-mb-5 {
    margin-bottom: -5px;
  }

  .md\:-ml-5 {
    margin-left: -5px;
  }

  .md\:-mt-10 {
    margin-top: -10px;
  }

  .md\:-mr-10 {
    margin-right: -10px;
  }

  .md\:-mb-10 {
    margin-bottom: -10px;
  }

  .md\:-ml-10 {
    margin-left: -10px;
  }

  .md\:-mt-15 {
    margin-top: -15px;
  }

  .md\:-mr-15 {
    margin-right: -15px;
  }

  .md\:-mb-15 {
    margin-bottom: -15px;
  }

  .md\:-ml-15 {
    margin-left: -15px;
  }

  .md\:-mt-20 {
    margin-top: -20px;
  }

  .md\:-mr-20 {
    margin-right: -20px;
  }

  .md\:-mb-20 {
    margin-bottom: -20px;
  }

  .md\:-ml-20 {
    margin-left: -20px;
  }

  .md\:-mt-25 {
    margin-top: -25px;
  }

  .md\:-mr-25 {
    margin-right: -25px;
  }

  .md\:-mb-25 {
    margin-bottom: -25px;
  }

  .md\:-ml-25 {
    margin-left: -25px;
  }

  .md\:-mt-30 {
    margin-top: -30px;
  }

  .md\:-mr-30 {
    margin-right: -30px;
  }

  .md\:-mb-30 {
    margin-bottom: -30px;
  }

  .md\:-ml-30 {
    margin-left: -30px;
  }

  .md\:-mt-35 {
    margin-top: -35px;
  }

  .md\:-mr-35 {
    margin-right: -35px;
  }

  .md\:-mb-35 {
    margin-bottom: -35px;
  }

  .md\:-ml-35 {
    margin-left: -35px;
  }

  .md\:-mt-40 {
    margin-top: -40px;
  }

  .md\:-mr-40 {
    margin-right: -40px;
  }

  .md\:-mb-40 {
    margin-bottom: -40px;
  }

  .md\:-ml-40 {
    margin-left: -40px;
  }

  .md\:-mt-45 {
    margin-top: -45px;
  }

  .md\:-mr-45 {
    margin-right: -45px;
  }

  .md\:-mb-45 {
    margin-bottom: -45px;
  }

  .md\:-ml-45 {
    margin-left: -45px;
  }

  .md\:-mt-50 {
    margin-top: -50px;
  }

  .md\:-mr-50 {
    margin-right: -50px;
  }

  .md\:-mb-50 {
    margin-bottom: -50px;
  }

  .md\:-ml-50 {
    margin-left: -50px;
  }

  .md\:-mt-55 {
    margin-top: -55px;
  }

  .md\:-mr-55 {
    margin-right: -55px;
  }

  .md\:-mb-55 {
    margin-bottom: -55px;
  }

  .md\:-ml-55 {
    margin-left: -55px;
  }

  .md\:-mt-60 {
    margin-top: -60px;
  }

  .md\:-mr-60 {
    margin-right: -60px;
  }

  .md\:-mb-60 {
    margin-bottom: -60px;
  }

  .md\:-ml-60 {
    margin-left: -60px;
  }

  .md\:-mt-65 {
    margin-top: -65px;
  }

  .md\:-mr-65 {
    margin-right: -65px;
  }

  .md\:-mb-65 {
    margin-bottom: -65px;
  }

  .md\:-ml-65 {
    margin-left: -65px;
  }

  .md\:-mt-70 {
    margin-top: -70px;
  }

  .md\:-mr-70 {
    margin-right: -70px;
  }

  .md\:-mb-70 {
    margin-bottom: -70px;
  }

  .md\:-ml-70 {
    margin-left: -70px;
  }

  .md\:-mt-75 {
    margin-top: -75px;
  }

  .md\:-mr-75 {
    margin-right: -75px;
  }

  .md\:-mb-75 {
    margin-bottom: -75px;
  }

  .md\:-ml-75 {
    margin-left: -75px;
  }

  .md\:-mt-80 {
    margin-top: -80px;
  }

  .md\:-mr-80 {
    margin-right: -80px;
  }

  .md\:-mb-80 {
    margin-bottom: -80px;
  }

  .md\:-ml-80 {
    margin-left: -80px;
  }

  .md\:-mt-85 {
    margin-top: -85px;
  }

  .md\:-mr-85 {
    margin-right: -85px;
  }

  .md\:-mb-85 {
    margin-bottom: -85px;
  }

  .md\:-ml-85 {
    margin-left: -85px;
  }

  .md\:-mt-90 {
    margin-top: -90px;
  }

  .md\:-mr-90 {
    margin-right: -90px;
  }

  .md\:-mb-90 {
    margin-bottom: -90px;
  }

  .md\:-ml-90 {
    margin-left: -90px;
  }

  .md\:-mt-95 {
    margin-top: -95px;
  }

  .md\:-mr-95 {
    margin-right: -95px;
  }

  .md\:-mb-95 {
    margin-bottom: -95px;
  }

  .md\:-ml-95 {
    margin-left: -95px;
  }

  .md\:-mt-100 {
    margin-top: -100px;
  }

  .md\:-mr-100 {
    margin-right: -100px;
  }

  .md\:-mb-100 {
    margin-bottom: -100px;
  }

  .md\:-ml-100 {
    margin-left: -100px;
  }

  .md\:-mt-105 {
    margin-top: -105px;
  }

  .md\:-mr-105 {
    margin-right: -105px;
  }

  .md\:-mb-105 {
    margin-bottom: -105px;
  }

  .md\:-ml-105 {
    margin-left: -105px;
  }

  .md\:-mt-110 {
    margin-top: -110px;
  }

  .md\:-mr-110 {
    margin-right: -110px;
  }

  .md\:-mb-110 {
    margin-bottom: -110px;
  }

  .md\:-ml-110 {
    margin-left: -110px;
  }

  .md\:-mt-115 {
    margin-top: -115px;
  }

  .md\:-mr-115 {
    margin-right: -115px;
  }

  .md\:-mb-115 {
    margin-bottom: -115px;
  }

  .md\:-ml-115 {
    margin-left: -115px;
  }

  .md\:-mt-120 {
    margin-top: -120px;
  }

  .md\:-mr-120 {
    margin-right: -120px;
  }

  .md\:-mb-120 {
    margin-bottom: -120px;
  }

  .md\:-ml-120 {
    margin-left: -120px;
  }

  .md\:-mt-px {
    margin-top: -1px;
  }

  .md\:-mr-px {
    margin-right: -1px;
  }

  .md\:-mb-px {
    margin-bottom: -1px;
  }

  .md\:-ml-px {
    margin-left: -1px;
  }

  .md\:-mt-0\.5 {
    margin-top: -0.5px;
  }

  .md\:-mr-0\.5 {
    margin-right: -0.5px;
  }

  .md\:-mb-0\.5 {
    margin-bottom: -0.5px;
  }

  .md\:-ml-0\.5 {
    margin-left: -0.5px;
  }

  .md\:-mt-1\.5 {
    margin-top: -1.5px;
  }

  .md\:-mr-1\.5 {
    margin-right: -1.5px;
  }

  .md\:-mb-1\.5 {
    margin-bottom: -1.5px;
  }

  .md\:-ml-1\.5 {
    margin-left: -1.5px;
  }

  .md\:-mt-2\.5 {
    margin-top: -2.5px;
  }

  .md\:-mr-2\.5 {
    margin-right: -2.5px;
  }

  .md\:-mb-2\.5 {
    margin-bottom: -2.5px;
  }

  .md\:-ml-2\.5 {
    margin-left: -2.5px;
  }

  .md\:-mt-3\.5 {
    margin-top: -3.5px;
  }

  .md\:-mr-3\.5 {
    margin-right: -3.5px;
  }

  .md\:-mb-3\.5 {
    margin-bottom: -3.5px;
  }

  .md\:-ml-3\.5 {
    margin-left: -3.5px;
  }
}
